import React from 'react'
import { withStyles, Switch, useTheme } from '@material-ui/core'

const CustomSwitch = ({ size, onClick, value }) => {
  const theme = useTheme()

  const StyledSwitch = withStyles({
    switchBase: {
      color: theme.palette.primary.light,
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch)

  return <StyledSwitch size={size} checked={value} onClick={() => {
    onClick()
  }} />
}

export default CustomSwitch
